.sidebar-container {
  width: 60px;
 display: flex;
 position: relative;
 overflow-y: auto;
 z-index: 1050;
 max-height: '100%';
 height: '100%';
 background-color: var(--background-color);
 }
 .sidebar-icon {
 width: 22px;
 height: 22px;
 color: var(--primary-color);
 margin-top: 7px;
 }
 .bg-white {
 position: fixed;
 width: 60px;
 height: '100%';
 background-color: var(--background-color);
 }
 .sidebar {
 position: fixed;
 left: 0;
 height: 95vh;
 width: 60px;
 color: "#093170";
 background-color: var(--background-color);
 display: flex;
 height: '100%';
 flex-direction: column;
 align-items: center;
 margin-bottom: 60px;
 justify-content: center;
 overflow-y: auto;
 }
 /* Default padding for viewport heights equal to or greater than 800px */
 .sidebar a.sidebar-item {
 padding-top: 1.3vh;
 padding-bottom: 1.3vh;
 display: block;
 margin-bottom: 7px;
 color: var(--primary-color);
 text-decoration: none;
 text-align: left;
 z-index: 997;
 }
 /* Padding for viewport heights between 500px and 799px */
 @media (max-height: 799px) and (min-height: 460px) {
 .sidebar a.sidebar-item {
 padding-top: 0.8vh;
 padding-bottom: 0.8vh;
 margin-bottom: 3px;
  }
 }
 /* Padding for viewport heights less than 500px */
 @media (max-height: 460px) {
 .sidebar a.sidebar-item {
 padding-top: 0;
 padding-bottom: 0;
 margin-bottom: 1px;
  }
 }
 .section-title {
 color: var(--primary-color);
 }
 .sidebar-item-push {
 padding-top:3vh;
 padding-bottom:3vh;
 display: block;
 margin-bottom: 7px;
 color: var(--primary-color);
 text-decoration: none;
 text-align: left;
 z-index: 997;
 }
 .sidebar li{
 display: block;
 margin-bottom: 10px;
 color: var(--primary-color);
 text-decoration: none;
 text-align: left;
 z-index: 997;
 }
 .off-canvas {
 position: fixed;
 top: 0;
 margin-top: 60px;
 left: -265px;
 height: 80vh;
 overflow-y: auto;
 width: 265px;
 background-color: var(--background-color);
 z-index: 997;
 }
 .modal-backdrop {
 z-index: 1040;
 }
 .off-canvas.active {
 left: 60px;
 }
 .off-canvas ul {
 margin: 0;
 padding: 0;
 list-style: none;
 }
 .off-canvas li {
 margin:0, 0 ,0 ,5;
 text-transform: capitalize;
 }
 .off-canvas a {
 color: var(--text-color);
 text-decoration: none;
 }
 ::-webkit-scrollbar {
 width: 5px;
 }
 ::-webkit-scrollbar-track {
 background: #f1f1f1;
 }
 ::-webkit-scrollbar-thumb {
 background: #888;
 }
 ::-webkit-scrollbar-thumb:hover {
 background-color: var(--primary-color);
 }
 .dropdown-submenu {
 position: relative;
 }
 .dropdown-submenu .dropdown-menu {
 top: 0;
 left: 100%;
 margin-top: -1px;
 }