.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 80%;
    max-width: 500px;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
  }
  
  .modal-image {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
  }
  
  .color-options {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .color-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid transparent;
    transition: border 0.2s;
    position: relative;
  }
  
  .color-circle:hover {
    border: 3px solid #999;
  }
  
  .color-circle.selected {
    border: 3px solid #333;
  }
  
  .color-label {
    position: absolute;
    bottom: 0px;
    /*background-color: white;*/
    font-size: 10px;
    text-align: center;
    width: 100px;
    color: white;
    margin-bottom: 10px;
  }
  
  
  .modal-submit {
    background: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background 0.2s;
  }
  
  .modal-submit:hover {
    background: #45a049;
  }
  