

.App {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial;
  text-align: left;
  color: var(--background-color);
}

header{
  z-index: 1090 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: var(--background-color);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}


.text-color{
  color: var(--text-color);
}


a{
  color: var(--primary-color);

}



.background-color{
  color: var(--background-color);
}

.footer {
  margin-left: 60px;
  border-top: 1px solid #eee;
  height: 40px;
  z-index: 996;
  text-align:   center;
}

